import styled from 'styled-components';
import ft_agenda_bg from '../../../images/Hero/ft_agenda_bg.png';
import ft_agenda_mobile_bg from '../../../images/Hero/ft_agenda_mobile_bg.png';

export default styled.section`
  background-image: linear-gradient(to top, transparent, #08080a 35%),
    url(${ft_agenda_bg});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  @media screen and (max-width: 768px) {
    background-image: linear-gradient(to top, transparent, #08080a 60%),
      url(${ft_agenda_mobile_bg});
    background-position: bottom;
  }
  .agenda-area {
    margin: 0 auto;
    padding-top: 90px;
    padding-bottom: 200px;
    max-width: 765px;
    @media screen and (max-width: 768px) {
      padding-top: 80px;
      padding-bottom: 80px;
    }
    p.agenda-title {
      font-family: Manufaktur-Bold, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 44px;
      line-height: 96%;
      text-align: center;
      letter-spacing: 1px;
      color: #fff;
      text-transform: uppercase;
      margin-bottom: 38px;
      @media screen and (max-width: 768px) {
        font-size: 40px;
      }
    }
    .accitem {
      border-top: 1px solid #c2c6cc;
      border-left: none;
      border-right: none;
      border-bottom: none;
      background: transparent;
      padding: 15px;
      @media screen and (max-width: 768px) {
        padding: 15px 0px;
      }
      .list-title {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 140%;
        color: #fff;
        @media screen and (max-width: 768px) {
          max-width:95%;
        }
        span {
          color: #fc6a2c;
          padding-right:7px;
        }
        img.plusicon{
          position: absolute;
          right: 10px;
          top: 26px;
          width:15px;
          height:15px;
        }
        img.minusicon{
          position: absolute;
          right: 10px;
          top: 26px;
          width:15px;
          height:15px;
        }
      }
      .list-body {
        p {
          font-style: normal;
          font-weight: normal;
          font-size: 16.2px;
          line-height: 140%;
          color: #d8dae6;
        }
        .sub-title {
          margin-top: 20px;
          color: #fc6a2c;
          font-weight: bold;
        }
        ul {
          color: #d8dae6;
          list-style: none;
          &.bullet-ul {
            list-style: outside;
            padding-left: 20px;
          }
        }
      }
    }
  }
`;
